@charset "UTF-8";
@-ms-viewport {
	width: device-width;
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 900;
	src: url('./Fonts/Gilroy-Black.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: bold;
	src: url('./Fonts/Gilroy-Bold.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 800;
	src: url('./Fonts/Gilroy-ExtraBold.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: bold;
	src: url('./Fonts/Gilroy-Heavy.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 500;
	src: url('./Fonts/Gilroy-Medium.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 400;
	src: url('./Fonts/Gilroy-Regular.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: bold;
	src: url('./Fonts/Gilroy-SemiBold.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 100;
	src: url('./Fonts/Gilroy-Thin.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 200;
	src: url('./Fonts/Gilroy-UltraLight.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 800;
	src: url('./Fonts/gilroyextrabold.woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 300;
	src: url('./Fonts/gilroylight.woff2');
}
:root {
	--bold: #232b2e !important;
	--black: #1c1f26 !important;
	--brown: #6e7892 !important;
	--blue: #5254f1 !important;
	--yellow: #ffe984 !important;
	--light-brown: #f9f6f3 !important;
	--brown-light: rgba(110, 120, 146, 0.5) !important;
	--white: #ffffff !important;
	--red: #ff5749 !important;
	--green: #46cb5c !important;
	--plyr-color-main: #5254f1 !important;
	--plyr-control-radius: 8px !important;
	--toastify-color-info: #5254f1;
	--toastify-color-success: #46cb5c;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #ff5749;
}
* {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	background: var(--white);
	color: var(--bold);
	font-weight: 500 !important;
	margin: 0 !important;
}
body,
button,
select,
input,
.plyr,
textarea {
	font-family: 'Gilroy', sans-serif !important;
}
::selection {
	background: var(--blue);
	color: var(--white);
}
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-track {
	background: var(--white);
}
::-webkit-scrollbar-thumb {
	background: var(--blue);
}
.tox-notifications-container {
	display: none !important;
}
a {
	cursor: pointer;
	text-decoration: none !important;
}
ul {
	list-style-type: none !important;
}
input {
	box-shadow: none !important;
	outline: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}
input[type='date'] {
	cursor: text !important;
}
textarea {
	resize: none !important;
}
button {
	box-shadow: none !important;
	cursor: pointer;
	outline: none !important;
}
.swiper-slide {
	align-items: center;
	display: flex;
	justify-content: center;
}
.swiper-pagination {
	bottom: 0 !important;
}
.swiper-pagination-bullet {
	height: 12px;
	margin: 0 7px !important;
	width: 12px;
}
.plyr__progress input[type='range'],
.plyr__volume input[type='range'] {
	cursor: pointer;
}
.plyr {
	-moz-border-radius: 20px !important;
	-ms-border-radius: 20px !important;
	-o-border-radius: 20px !important;
	-webkit-border-radius: 20px !important;
	border-radius: 20px !important;
	overflow: hidden !important;
}
.Toastify__toast {
	border-radius: 8px;
	color: var(--bold);
	font-family: 'Gilroy', sans-serif;
	font-weight: 700;
}
.Toastify__toast--warning .Toastify__progress-bar {
	background-color: var(--toastify-color-warning);
}
.Toastify__toast--success .Toastify__progress-bar {
	background-color: var(--toastify-color-success);
}
.Toastify__toast--error .Toastify__progress-bar {
	background-color: var(--toastify-color-error);
}
.Toastify__toast--info .Toastify__progress-bar {
	background-color: var(--toastify-color-info);
}
.Toastify__close-button {
	color: var(--bold);
	opacity: 1;
}
.Toastify__close-button svg {
	fill: var(--bold);
}
.wrs_editor td {
	padding: 0 !important;
}
.tox-tinymce-aux {
	z-index: 999999 !important;
}
